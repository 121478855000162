

























































import { LogLevels } from "@/models/log/interface";

export default {
  name: "ResetPassword",
  components: {},
  data() {
    return {
      valid: false,
      emailRules: [
        v => !!v || this.$i18n.t("components/register/errors/email_empty"),
        v =>
          /.+@.+\..+/.test(v) ||
          this.$i18n.t("components/register/errors/email_invalid")
      ],
      email: "",
      emailLoading: false
    };
  },
  methods: {
    sendRecoverEmail(): void {
      this.emailLoading = true;
      this.$store
        .dispatch("user/sendRecoverEmail", this.email)
        .then(() => {
          // console.log("email sent");
        })
        .catch((error: string) => {
          this.$store.dispatch("log", {
            level: LogLevels.ERROR,
            message: error
          });
        })
        .finally(() => {
          this.$store.commit("showMessage", {
            text: this.$t("views/reset_password/email_sent"),
            color: "success"
          });
          this.$router.push({ name: "home" });
          this.emailLoading = false;
        });
    }
  }
};
